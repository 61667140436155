<template>
  <div class="salary">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <AnnounceModal
      v-model="isAnnounceModalOpen"
      :description="$t('simulateurs.salary.modal-description')"
      :subDescription="$t('simulateurs.salary.modal-subdescription')"
      @close="closeAnnounceModal"
    />

    <div class="salary-content page-layout">
      <md-card class="meep-form">
        <PageHeader
          :title="$t('simulateurs.salary.title')"
          :has-back="true"
          :hasActions="true"
          @back="goBack()"
        >
          <md-button
            class="md-primary header-action header-action-icon md-button-no-margin"
            @click="showAnnounceModal"
          >
            <MeepIconCircleQuestion class="app-icon" />
          </md-button>
        </PageHeader>
        <md-card-content>
          <div class="meep-form-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.salary.montant") }}</label>
                <md-input
                  v-model.number="$v.form.montant.$model"
                  name="montant"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.montant.$dirty">
                  <span v-show="!$v.form.montant.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.montant.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <Chooser
              v-model="form.netBrut"
              :value="form.netBrut"
              :items="[
                { label: 'Net', value: 'net' },
                { label: 'Brut', value: 'brut' },
              ]"
              :twoItems="true"
            />

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-text">
                {{ $t("simulateurs.salary.chooser-2-title") }}
              </p>
            </div>

            <Chooser
              v-model="form.type"
              :value="form.type"
              :items="[
                { label: 'Mensuel', value: 'mensuel' },
                { label: 'Annuel', value: 'annuel' },
              ]"
              :noMargin="true"
              :twoItems="true"
            />

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-text">
                {{ $t("simulateurs.salary.chooser-3-title") }}
              </p>
            </div>

            <Chooser
              v-model="form.entreprise"
              :value="form.entreprise"
              :items="[
                { label: '1 à 19', value: '1' },
                { label: '20 et plus', value: '2' },
              ]"
              :noMargin="true"
              :twoItems="true"
            />

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.salary.taux") }}</label>
                <md-input v-model.number="$v.form.taux.$model" name="taux" />
                <span class="md-suffix">{{ $t("suffix.percent") }}</span>

                <template v-if="$v.form.taux.$dirty">
                  <span v-show="!$v.form.taux.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.taux.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>
          </div>
          <div class="meep-form-column">
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.revenuNet") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.revenuNet) +
                      $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.revenuBrut") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.revenuBrut) +
                      $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.chargesSalariales") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.chargesSalariales) +
                      $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.chargesPatronales") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.chargesPatronales) +
                      $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.fillon") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.fillon) + $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.salary.coutGlobal") }}</label>
                <p class="result">
                  {{
                    $$filters.formatNumber(results.coutGlobal) +
                      $t("suffix.euro")
                  }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import MeepIconCircleQuestion from "@/components/icons/MeepIconCircleQuestion.vue";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";

import HasBackMixin from "@/mixins/HasBackMixin";
import { required, numeric } from "vuelidate/lib/validators";
import PageHeader from "@/components/PageHeader";
import calculatorsModel from "@/model/calculators";
import Chooser from "@/components/Chooser.vue";
import { tauxs } from "../../constants";

export default {
  name: "Salary",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    Chooser,
    MeepIconCircleQuestion,
    AnnounceModal,
  },

  validations: {
    form: {
      montant: { required, numeric },
      taux: { required, numeric },
    },
  },

  data() {
    return {
      form: {
        montant: null,
        netBrut: "net",
        type: "mensuel",
        entreprise: "1",
        taux: 42,
        updatedAt: null,
      },
      tauxs,
      results: {
        revenuNet: 0,
        revenuBrut: 0,
        chargesSalariales: 0,
        chargesPatronales: 0,
        fillon: 0,
        coutGlobal: 0,
      },
      salaryData: [],
      isAnnounceModalOpen: false,
    };
  },

  async mounted() {
    this.salaryData = await calculatorsModel.getAllSalaire();
  },

  methods: {
    calcu({ montant, type, netBrut, entreprise, taux }) {
      const salaire = type === "mensuel" ? Number(montant) : montant / 12;

      const coef =
        entreprise === "1"
          ? this.salaryData[0].coef1a19
          : this.salaryData[0].coef20plus;

      const net = netBrut === "net" ? salaire : salaire * 0.78;

      const brut = netBrut === "brut" ? salaire : salaire / 0.78;

      const tauxCharge = taux * 0.01;
      const smic = this.salaryData[0].smic * 151.57;
      const F = brut * ((coef / 0.6) * ((1.6 * smic) / brut - 1)); // reduction

      const fillon = F > 0 ? F : 0;

      const chargesSalariales = brut * 0.22;
      const chargesPatronales = tauxCharge * brut - fillon;
      const coutGlobal = brut + chargesPatronales;

      this.results = {
        revenuNet: Number(net),
        revenuBrut: Number(brut),
        chargesSalariales: Number(chargesSalariales),
        chargesPatronales: Number(chargesPatronales),
        fillon: Number(fillon),
        coutGlobal: Number(coutGlobal),
      };
    },

    showAnnounceModal() {
      this.isAnnounceModalOpen = true;
    },

    closeAnnounceModal() {
      this.isAnnounceModalOpen = false;
    },
  },
};
</script>
